<template>
  <v-card flat>
    <b> &nbsp;&nbsp; Downhole Test at {{ date }}</b>
    <v-row style="margin: 4px">
      <v-row dense no-gutters>
        <v-col cols="12" sm="2" md="2"> </v-col>

        <v-col cols="12" sm="1" md="1" align="center">PV</v-col>
        <v-col cols="12" sm="2" md="1" align="center">PI</v-col>
        <v-col cols="12" sm="1" md="1" align="center">PF</v-col>
        <v-col cols="12" sm="1" md="1" align="center">Drp</v-col>

        <v-col cols="12" sm="4" md="5" align="center">Communication</v-col>
      </v-row>
      <v-row class="rowh2" dense v-for="item in list" :key="item.id">
        <v-col cols="12" sm="2" md="2">
          <span dense>{{ item.label }}</span></v-col
        >

        <v-col cols="12" sm="2" md="1">
          <v-text-field
            :readonly="true"
            autocomplete="off"
            type="number"
            outlined
            dense
            v-model="item.p_volume"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-text-field
            :readonly="true"
            autocomplete="off"
            type="number"
            outlined
            dense
            v-model="item.i_pressure"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-text-field
            :readonly="true"
            autocomplete="off"
            type="number"
            outlined
            dense
            v-model="item.f_pressure"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-text-field
            :readonly="true"
            autocomplete="off"
            type="number"
            outlined
            dense
            v-model="item.drop_pressure"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="4" md="2">
          <v-text-field
            autocomplete="off"
            type="number"
            outlined
            dense
            v-model="item.anl_b"
            :label="getlabel(2, item.csg_order)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-text-field
            autocomplete="off"
            type="number"
            outlined
            dense
            v-model="item.anl_c"
            :label="getlabel(3, item.csg_order)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-text-field
            autocomplete="off"
            type="number"
            outlined
            dense
            v-model="item.anl_d"
            :label="getlabel(4, item.csg_order)"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    list: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    auth() {
      let a = "01007";

      return a;
    },
    date() {
      if (this.list.length > 0) return this.list[0].testdate;
      else return null;
    },
  },
  watch: {},
  methods: {
    getlabel(index, order) {
      if (order == 1) {
        return (index + 9).toString(36).toUpperCase() + "-Annulus";
      }
      if (order == 2) {
        if (index == 1) return "Tube";
        if (index == 2) return "B- Annulus";
        if (index == 3) return "C- Annulus";
        if (index == 4) return "D- Annulus";
        if (index == 5) return "E- Annulus";
      }
      if (order == 3) {
        if (index == 1) return "Tube";
        if (index == 2) return "A- Annulus";
        if (index == 3) return "C- Annulus";
        if (index == 4) return "D- Annulus";
        if (index == 5) return "E- Annulus";
      }
      if (order == 4) {
        if (index == 1) return "Tube";
        if (index == 2) return "A- Annulus";
        if (index == 3) return "B- Annulus";
        if (index == 4) return "D- Annulus";
        if (index == 5) return "E- Annulus";
      }
      if (order == 5) {
        if (index == 1) return "Tube";
        if (index == 2) return "A- Annulus";
        if (index == 3) return "B- Annulus";
        if (index == 4) return "c- Annulus";
        if (index == 5) return "E- Annulus";
      }
      if (order == 6) {
        if (index == 1) return "Tube";
        if (index == 2) return "A- Annulus";
        if (index == 3) return "B- Annulus";
        if (index == 4) return "C- Annulus";
        if (index == 5) return "D- Annulus";
      }
    },
  },
};
</script>
<style>
.rowh2 {
  height: 40px !important;
  margin-bottom: 8px !important;
}
</style>
